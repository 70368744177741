import React from 'react'
import '../../App.css'


export default function Contact() {
    return (
    <>
    <div>
    <h1 className='contact'>Let us help you with your next project!</h1>
    <h1>Info coming soon</h1>


    </div>
    </>
    );
}