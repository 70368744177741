import React from 'react'
import Footer from '../Footer'
import Profile from '../Profile'
import '../Profile.css'
// import '../../App.css'

export default function About() {
    return (
        <>
        <div className='about'>
            <h1 className='about-header'>About Squatcode.com</h1>
            {/* Here will add information about each person*/}
            <div className='about-container'>
                <div className='about-wrapper'>
                    <ul className='profile-items'>
                    <Profile
                    src='./images/profile.jpg'
                    name='Parker'
                    description='Software Developer'
                    path/>

                    <Profile
                    src='./images/profile.jpg'
                    name='Seth'
                    description='Software Developer'
                    path/>
                </ul>
                <ul className='profile-items'>                     
                    <Profile
                    src='./images/profile.jpg'
                    name='Henry'
                    description='Software Developer'
                    path/>

                                                            
                    <Profile
                    src='./images/profile.jpg'
                    name='Alex'
                    description='Software Developer'
                    path/>
                    
                </ul>
                </div>

            </div>

        </div>
            <Footer id='contact'/>
    </>
    )
}