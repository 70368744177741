import React from 'react'
import { Button } from './Button'
import './Footer.css'
function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-header'>
        <p className='footer-heading'>
            Interested?
        </p>
        <p className='footer-text'>
            Let us take help your buisness to the next level
        </p>
        <div className='input-areas'>
        <Button href="" class="btn btn-primary">Send us an Email!</Button>
        </div>
      </section>
    </div>
  )
}

export default Footer
