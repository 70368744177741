import React from 'react'
import '../../App.css'
import Hero from '../Hero'
import Cards from '../Cards'
import Footer from '../Footer'

function Home () {
    return (
        <>
            <Hero id='hero'/>
            <Cards id='projects'/>
            <Footer id='contact'/>
        </>
    )
}

export default Home;