import React from 'react'
import CardItem from './CardItem'
import './Cards.css'

function Cards() {
  return (
    <div className='cards'>
      <h1>Here are some of our Projects!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
            <ul className='cards__items'>
                <CardItem
                src='images/sudoku.jpg'
                text="Henry's Sudoku Solver"
                label="Python"
                path='/'
                />
            </ul>
            {/* copy and paste the card item list to add projects */}
        </div>
      </div>
    </div>
  )
}

export default Cards
