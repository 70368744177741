import React from 'react'
import '../App.css'
import './Hero.css'

import { Button } from './Button'
function Hero() {
  return (
    <div className='hero-container'>
      <h1>WELCOME TO SQUATCODE.COM</h1>
      <p>Here for all your Web App needs</p>
      <div className='hero-btns'>

      </div>
    </div>
  );
}

export default Hero
