import React from 'react'
import { Link } from 'react-router-dom'
import './Profile.css'

function Profile(props) {
    return(
        <>
        <li className='profile-pic'>
            <Link className='proflie-pic-link' to={props.path}>
            <figure className='profile-pic-wrap'>
                <img src={props.src} alt="profile" className='profile-img'/>
            </figure>

            <div className='profile-caption-box'>
                <h3 className='profile-caption-name'>{props.name}</h3>
                <p className='profile-description-text'>{props.description}</p>
            </div>
            </Link>
        </li>
        </>
    )
}

export default Profile